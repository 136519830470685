<template>
  <div id="app">
    <b-carousel :interval="4000"
                class="shadow" 
                img-height="480"
                height="200" 
                background="#ababab">
      <b-carousel-slide  height="100" :img-src="isMobile ? 'mediano.jpg' : 'grande.jpg'"/>
    </b-carousel> 
    <br>
    <div class="container-fluid">
      <h1 :class="isMobile ? 'text-center mt-3' : 'text-center mt-4'" style="color:#0b2739;"><b>Promo Colaboradores</b></h1>
      <center><hr :style="isMobile ?  'border-top: 5px solid #d3d8db !important; width: 88%;' : 'border-top: 5px solid #d3d8db !important; width: 40%;'"> </center>
      <h3 :class="isMobile ? 'text-center mt-3' : 'text-center mt-4'" ><b>Arena the Place to Play Movistar</b></h3>
      <h3 :class="isMobile ? 'text-center mt-3' : 'text-center mt-4'" ><b>Beneficios</b></h3>
      <div :class="isMobile ? 'row mt-4' : 'row mt-5'"> 
        <div class="col-md-2 text-left" ></div>
        <div class="col-1 text-left">
          <img src="circle.svg" :class="isMobile ? 'rounded mt-2' : 'mt-3 rounded'" alt="Image" :width="isMobile ? 16 : 25">
        </div>
        <div class="col-md-8 col-11 text-left" :style="isMobile ? '' : 'margin-left: -85px;'">
          <label class="mt-2" :style="isMobile ? 'font-size: 15px;' : 'font-size: 21px;'">
            Vigencia al 14 de septiembre de 2025 en cualquier sucursal de Arena the Place to Play Movistar a nivel nacional.  
           </label>
        </div>
        <div class="col-md-12"><br><br></div>
        <div class="col-md-2 text-left" ></div>
        <div class="col-1 text-left">
          <img src="circle.svg" :class="isMobile ? 'rounded mt-2' : 'mt-3 rounded'" alt="Image" :width="isMobile ? 16 : 25">
        </div>
        <div class="col-md-8 col-11 text-left" :style="isMobile ? '' : 'margin-left: -85px;'">
          <label class="mt-2" :style="isMobile ? 'font-size: 15px;' : 'font-size: 21px;'"> 
            Consulta la sucursal más cercana en: <a href="https://bit.ly/4aKMpEn" target="_blank" rel="noopener noreferrer">https://bit.ly/4aKMpEn</a>
           </label>
        </div>
        <div class="col-md-12"><br><br></div>
        <div class="col-md-2 text-left" ></div>
        <div class="col-1 text-left">
          <img src="circle.svg" :class="isMobile ? 'rounded mt-2' : 'mt-3 rounded'" alt="Image" :width="isMobile ? 16 : 25">
        </div>
        <div class="col-md-8 col-11 text-left" :style="isMobile ? '' : 'margin-left: -85px;'">
          <label class="mt-2" :style="isMobile ? 'font-size: 15px;' : 'font-size: 21px;'">
            Todos los colaboradores de Movistar podrán hacer uso de 2 horas de juego sin costo de manera semanal adquiriendo alguno de los combos participantes.
           </label>
        </div>
        <div class="col-md-12"><br><br></div>
         <div class="col-md-2 text-left"></div>
          <div class="col-1 text-right">
            <img src="circle.svg" :class="isMobile ? 'rounded mt-2' : 'mt-3 rounded'" alt="Image" :width="isMobile ? 16 : 25">
          </div>
          <div class="col-md-7 col-11 text-left" :style="isMobile ? '' : 'margin-left: -85px;'">
            <label class="mt-2" :style="isMobile ? 'font-size: 15px;' : 'font-size: 21px;'">
               Las horas de juego no son acumulables; es decir que, en caso de no usar las horas en la semana corriente, se darán por perdidas. 
            </label>
          </div>
         <div class="col-md-12"><br><br></div>
         <div class="col-md-2 text-left"></div>
          <div class="col-1 text-right">
            <img src="circle.svg" :class="isMobile ? 'rounded mt-2' : 'mt-3 rounded'" alt="Image" :width="isMobile ? 16 : 25">
          </div>
          <div class="col-md-7 col-11 text-left" :style="isMobile ? '' : 'margin-left: -85px;'">
            <label class="mt-2" :style="isMobile ? 'font-size: 15px;' : 'font-size: 21px;'">
              Las horas de juego pueden ser utilizadas por el colaborador o un hijo(a) y para hacer uso de las mismas deberá asistir el colaborador y presentar su credencial de Movistar.
              En caso de que las horas de juego sean utilizadas por un hijo(a) deberán presentar el acta de nacimiento o documento que acredite al colaborador como padre o tutor del hijo(a).
            </label>
          </div>
          <div class="col-md-12"><br><br></div>
          <div class="col-md-2 text-left"></div>
            <div class="col-1 text-right">
              <img src="circle.svg" :class="isMobile ? 'rounded mt-2' : 'mt-3 rounded'" alt="Image" :width="isMobile ? 16 : 25">
            </div>
            <div class="col-md-7 col-11 text-left" :style="isMobile ? '' : 'margin-left: -85px;'">
              <label class="mt-2" :style="isMobile ? 'font-size: 15px;' : 'font-size: 21px;'">
                Las horas de juego son válidas únicamente en zonas de PC y consolas <span :style="isMobile ? 'font-size: 12px;' : 'font-size: 18px;'">(No aplican salas especiales y VR).</span> 
              </label>
            </div>
         <div class="col-md-12"><br><br></div>
          <div class="col-md-2 text-left"></div>
            <div class="col-1 text-right">
              <img src="circle.svg" :class="isMobile ? 'rounded mt-2' : 'mt-3 rounded'" alt="Image" :width="isMobile ? 16 : 25">
            </div>
            <div class="col-md-7 col-11 text-left" :style="isMobile ? '' : 'margin-left: -85px;'">
              <label class="mt-2" :style="isMobile ? 'font-size: 17px;' : 'font-size: 27px;'">
                <b>Combos participantes:</b>
              </label>
            </div>
            <div class="col-md-12 text-center">
              <br> 
              <div class="table-responsive">
                <img src="tabla.jpg" :class="isMobile ? 'rounded mt-2' : 'mt-3 rounded'"  alt="Image" :width="isMobile ? 1080 : 1820">
              </div>
                 <label class="mt-2" :style="isMobile ? 'font-size: 12px; color:#0b2739;' : 'font-size: 15px; color:#0b2739;'"> 
                  El uso de las horas de juego podrá disfrutarse siempre y cuando exista la disponibilidad de estaciones de juego. 
                </label>
            </div>
      </div>
     </div>
     <br> 
  </div>
</template>

<script> 
export default {
  name: 'App', 
      data() {
        return {  
           isMobile: this.$mobile(),
        }
      }
}
</script> 